import React, { Component } from 'react'
import { Row, Col, Carousel } from 'react-bootstrap';
import { GET } from 'api'
import CarType from './component/car-type'
import Article from './component/article'
import SpecialOffer from './component/special-offer'
import Promotion from './component/promotion'
import News from './component/news'
import Service from './component/service'

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            banner: [],
            article: [],
            promotions: [],
            news: []
        }
    }

    componentDidMount = async () => {
        this.getBanner()
        this.getArticle()
        this.getPromotion()
        this.getNews()
    }
    getBanner = async () => {
        try {
            let banner = await GET('/banner/')
            this.setState({ banner: banner })
        } catch (error) {
            console.log('getBanner error', error)
        }
    }
    getArticle = async () => {
        try {
            let article = await GET('/article/')
            this.setState({ article })
        } catch (error) {
            console.log(error)
        }
    }

    getPromotion = async () => {
        try {
            let promotions = await GET('/promotion/')
            this.setState({ promotions })
        } catch (error) {
            console.log(error)
        }
    }

    getNews = async () => {
        try {
            let news = await GET('/news/')
            this.setState({ news })
        } catch (error) {
            console.log(error)

        }
    }

    render() {
        return (
            <div className="home">
                {this.state.banner.length > 0 &&
                    < div className="-slide">
                        < Carousel
                            className="custom-carousel"
                            nextIcon={<i className="fas fa-chevron-right h1"></i>}
                            prevIcon={<i className="fas fa-chevron-left h1"></i>}>
                            {this.state.banner.map((e, i) =>
                                <Carousel.Item key={i}>
                                    <img src={e.path} alt="" />
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </div>
                }

                <CarType />
                <Article data={this.state.article} />
                <SpecialOffer />
                <Promotion data={this.state.promotions} />
                <News data={this.state.news} />
                <Service data={this.state.promotions} />
            </div >
        )
    }
}
