import React, { Component, authed } from 'react';
import { NavLink } from 'utils';
import { GET } from '../../api/api';
import './sidebar.scss';

export default class Sidebar extends Component {
  render() {
    const { logout } = this.props
    switch (this.props.role) {
      case 'admin':
        return (
          <div className='sidebar'>
            <div className='logo'>
              <img src={require('../../images/logo-white.png')} alt='logo' />
            </div>
            <ul className='menu'>
              <li className='link'>
                <NavLink to='/admin/user-data' classActive='active'>
                  ข้อมูลลูกค้า
                </NavLink>
              </li>
              <li className='link'>
                <NavLink to='/admin/banner' classActive='active'>
                  แบนเนอร์
                </NavLink>
              </li>
              <li className='link'>
                <NavLink to='/admin/car' classActive='active' tag='/car'>
                  ผลิตภัณฑ์
                </NavLink>
              </li>
              <li className='link'>
                <NavLink
                  to='/admin/promotion'
                  classActive='active'
                  tag='/promotion'
                >
                  โปรโมชั่น
                </NavLink>
              </li>
              <li className='link'>
                <NavLink
                  to='/admin/article'
                  classActive='active'
                  tag='/article'
                >
                  บทความ
                </NavLink>
              </li>
              <li className='link'>
                <NavLink to='/admin/news' classActive='active' tag='/news'>
                  ข่าวสาร
                </NavLink>
              </li>
              <li className='link' onClick={() => logout()}>
                <NavLink to='/admin/login' classActive='active'>
                  ออกจากระบบ
                </NavLink>
              </li>
            </ul>
          </div>
        );
        break;
      default:
        return <React.Fragment></React.Fragment>;
    }
  }
}
