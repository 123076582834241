import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './band.scss';

export default class Band extends Component {
  render() {
    let { text } = this.props;
    return (
      <div className='band'>
        <div className='-text'>
          <h3>{text}</h3>
        </div>

        <Image src={require('../../images/Heading-1.png')} />
      </div>
    );
  }
}
