import React from 'react'

export default function Service({ data }) {
    return (
        <div className="-service article-box">
            <div className="-title">บริการของเรา</div>
            <div className="d-flex flex-wrap mx-auto" style={{ maxWidth: '1300px' }}>
                <div className="news-article mx-auto mb-3" >
                    <div className="-img">
                        <img src={require('../images/service_car.png')} alt="" />
                    </div>
                    <div className="-content">
                        <div className="-title">จัดจำหน่าย</div>
                        <div className="-desc">
                            ถ้าคุณเลือก ออกรถมอเตอร์ไซค์กับเรา คุณจะสามารถรับรถกลับบ้านได้เลย ง่าย สะดวก รวดเร็ว เพราะเราเป็นมืออาชีพด้านการขายรถมอเตอร์ไซค์
                        </div>
                    </div>
                </div>
                <div className="news-article mx-auto" >
                    <div className="-img">
                        <img src={require('../images/repair_service.png')} alt="" />
                    </div>
                    <div className="-content">
                        <div className="-title">บริการซ่อมบำรุง</div>
                        <div className="-desc">
                            ออกรถมอไซค์ร้านเรา หมดกังวลเรื่องการซ่อมรถ
                            มีศูนย์ซ่อมบริการหลังการขาย และอะไหล่แท้จากฮอนด้าโดยตรง
                        </div>
                    </div>
                </div>
                <div className="news-article mx-auto" >
                    <div className="-img">
                        <img src={require('../images/try_service.png')} alt="" />
                    </div>
                    <div className="-content">
                        <div className="-title">ทดลองขับ</div>
                        <div className="-desc">
                            เพื่อประกอบการตัดสินใจในการซื้อรถและสัมผัสประสบการณ์การขับขี่รถจักรยานยนตร์ คุณสามารถขอทดลองขับกับทางเราได้
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-img">
                <img src={require('../images/carbig.png')} />
            </div>
        </div>
    )
}