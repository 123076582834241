import React from 'react';
import NavigationRouter from 'navigations'

const App = () => {
  return (
    <React.Fragment>
      <NavigationRouter />
    </React.Fragment>
  )
}

export default App;
