import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './routes/private-route';
import PublicRoute from './routes/public-route';
import { Navbar, Sidebar, Footer } from 'components';
import { GET } from '../api/api';
import './navigation.scss';

// import another page
import { NotFound, Unauthorize, Template } from 'containers';
//import user page
const Home = lazy(() => import('containers/user/home/home'));
const Promo = lazy(() => import('containers/user/promo'));
const News = lazy(() => import('containers/user/news'));
const UserArticle = lazy(() => import('containers/user/article'));
const Products = lazy(() => import('containers/user/product/products'));
const ProductDetail = lazy(() =>
  import('containers/user/product/product-detail')
);
const ProductDown = lazy(() => import('containers/user/product/product-down'));
const DetailTemplate = lazy(() => import('containers/user/detail-template'));
const Contact = lazy(() => import('containers/user/contact'));
const ProductCompare = lazy(() => import('containers/user/product-compare'));

//import admin page
const Login = lazy(() => import('containers/admin/login'));
const Car = lazy(() => import('containers/admin/car/car'));
const AddCar = lazy(() => import('containers/admin/car/add-car'));
const AdminArticle = lazy(() => import('containers/admin/article/article'));
const AddArticle = lazy(() => import('containers/admin/article/add-article'));
const AdminNews = lazy(() => import('containers/admin/news/news'));
const AddNews = lazy(() => import('containers/admin/news/add-news'));
const AdminPromotion = lazy(() =>
  import('containers/admin/promotion/promotion')
);
const AddPromotion = lazy(() =>
  import('containers/admin/promotion/add-promotion')
);
const AdminBanner = lazy(() => import('containers/admin/banner/banner'));
const AdminUserData = lazy(() =>
  import('containers/admin/user-data/user-data')
);

export default class NavigationRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      authen: false,
    };
  }

  componentDidMount = () => {
    this.checkAuth();
  };
  checkAuth = async () => {
    try {
      let isLogin = await GET('/authen/');
      this.setState({
        authen: true,
      });
      console.log('pass');
    } catch (err) {
      console.log(err);
    }
    if (window.location.pathname.includes('/admin')) {
      this.setState({ role: 'admin', loading: false });
    } else {
      this.setState({ role: 'user', loading: false });
    }
  }

  logout = async () => {
    try {
      let isLogout = await GET('/authen/logout');
      console.log('logout');
      this.setState({
        authen: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let { authen } = this.state;
    if (this.state.loading) {
      return <div />;
    } else {
      return (
        <Router>
          <Navbar role={this.state.role} />
          {authen ? <Sidebar role={this.state.role} logout={this.logout} /> : null}

          <Suspense fallback={<div className='h1'>Loading...</div>}>
            <div
              className={`${
                this.state.role === 'admin' && authen
                  ? 'navigation -addsidebar'
                  : 'navigation'
                }`}
            >
              <Switch>
                {/* --------- user ------------ */}
                <PublicRoute component={Home} path={'/'} exact />
                <PublicRoute component={Template} path={'/template'} exact />
                <PublicRoute component={Promo} path={'/promo'} exact />
                <PublicRoute component={News} path={'/news'} exact />
                <PublicRoute component={UserArticle} path={'/article'} exact />
                <PublicRoute
                  component={Products}
                  path={'/products'}
                  exact
                />
                <PublicRoute
                  component={ProductDetail}
                  path={'/products/product_detail/:id/:name'}
                  exact
                />
                <PublicRoute
                  component={DetailTemplate}
                  path={'/detail/:location/:id/:name'}
                />
                <PublicRoute component={Contact} path={'/contact'} />
                <PublicRoute
                  component={ProductCompare}
                  path={'/compare/:id?'}
                />
                <PublicRoute
                  component={ProductDown}
                  path={'/products/down/:id?/:name'}
                  exact
                />
                {/* <PublicRoute component={NotFound} path={'/'} exact /> */}
                {/* --------- admin ------------ */}
                <PrivateRoute
                  component={Car}
                  path={'/admin'}
                  authed={authen}
                  exact
                />
                <PrivateRoute
                  component={Car}
                  path={'/admin/car'}
                  authed={authen}
                  exact
                />
                <PrivateRoute
                  component={AddCar}
                  path={'/admin/car/edit/:id?'}
                  authed={authen}
                />
                <PrivateRoute
                  component={AdminArticle}
                  path={'/admin/article'}
                  authed={authen}
                  exact
                />
                <PrivateRoute
                  component={AddArticle}
                  path={'/admin/article/edit/:id?'}
                  authed={authen}
                />
                <PrivateRoute
                  component={AdminNews}
                  path={'/admin/news'}
                  authed={authen}
                  exact
                />
                <PrivateRoute
                  component={AddNews}
                  path={'/admin/news/edit/:id?'}
                  authed={authen}
                />
                <PrivateRoute
                  component={AdminPromotion}
                  path={'/admin/promotion'}
                  authed={authen}
                  exact
                />
                <PrivateRoute
                  component={AddPromotion}
                  path={'/admin/promotion/edit/:id?'}
                  authed={authen}
                />
                <PrivateRoute
                  component={AdminBanner}
                  path={'/admin/banner'}
                  authed={authen}
                />
                <PrivateRoute
                  component={AdminUserData}
                  path={'/admin/user-data'}
                  authed={authen}
                />
                {/* --------- another ------------ */}

                <Route path='/unauthorize' component={Unauthorize} />
                <Route path='/admin/login' component={Login} />
                <Route path='*' component={NotFound} />
              </Switch>
            </div>
          </Suspense>
          <Footer role={this.state.role} />
        </Router>
      );
    }
  }
}
