import React from 'react'
import Slider from "react-slick";
import { slide_article_settings } from './slide-setting'

export default function Promotion({ data }) {
    return (
        <div className="article-box">
            <div className="article-review">

                <div className="-content">
                    <strong>โปรโมชั่นของเรา</strong><br />
                    <span>PROMOTION</span>
                    <div className="mt-3">
                        <button
                            onClick={() => window.location.assign(`/promo`)}
                            className="btn btn-primary ">
                            ดูโปโมชั่นทั้งหมด
                        </button>
                    </div>
                </div>
                <div className="-img"><img src={require('../images/promotion.png')} alt="carbig" /></div>
            </div>
            <Slider {...slide_article_settings} className="-slide">
                {data.slice(0, 3).map((e, i) =>
                    <div key={i}>
                        <div className="news-article mx-auto" >
                            <div className="-img">
                                <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => window.location.assign(`/detail/promo/${e.pro_id}/${e.pro_topic}`)}
                                    className="-more">
                                    ดูเพิ่มเติม
                            </a>
                                <img src={e.path} alt="carbig" />
                            </div>
                            <div className="-content">
                                <div className="-title">{e.pro_topic}</div>
                                <div className="-desc" dangerouslySetInnerHTML={{ __html: e.pro_detail }} />
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    )
}