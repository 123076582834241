import React, { useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { POST } from 'api'
import ReCAPTCHA from "react-google-recaptcha";
import swal from 'sweetalert';

export default function SpecialOffer({ data }) {
    const recaptchaRef = React.createRef();
    const [form, setform] = useState({})

    const onChangeText = ({ target: { name, value } }) => {
        setform({ ...form, [name]: value })
    }
    const onSubmit = async () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        try {
            if (form.name && form.tel && form.career && form.email) {
                if (recaptchaValue) {
                    await POST('/user/add_contact', form)
                    swal({
                        title: 'success',
                        text: 'ทำรายการสำเร็จ',
                        icon: 'success',
                        dangerMode: true,
                    });
                } else {
                    swal({
                        title: 'failed',
                        text: 'please check reCAPTCHA',
                        icon: 'warning',
                        dangerMode: true,
                    });
                }
            } else {
                swal({
                    title: 'failed',
                    text: 'กรุณากรอกข้อมูลให้ครบ',
                    icon: 'warning',
                    dangerMode: true,
                });
            }
        } catch (error) {
            swal({
                title: 'failed',
                text: error,
                icon: 'warning',
                dangerMode: true,
            });
        }

    }
    return (
        <div className="special_offer">
            <div>
                <div className="-left">
                    <img src={require('../images/car-contract.png')} alt="carbig" />
                </div>
                <div className="-right">
                    <div className="-content">
                        <div className="-topic">
                            <p>ข้อเสนอดีสุด รับรองคุณจะได้</p>
                            <p>มอเตอร์ไซต์ในราคาที่ถูกและ</p>
                            <p>คุ้มค่าที่สุดมากกว่าที่ไหน</p>
                        </div>
                        <div className="g-title mx-auto">ลงทะเบียน รับโปรโมชั่น ข้อเสนอพิเศษ</div>
                        <div className="-form">
                            <Form.Group>
                                <Form.Control placeholder="ชื่อ-นามสกุล" name="name" onChange={onChangeText} />
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Control placeholder="เบอร์โทรศัพท์" name="tel" onChange={onChangeText} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Control placeholder="อาชีพ" name="career" onChange={onChangeText} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Form.Control type="email" placeholder="E-mail" name="email" onChange={onChangeText} />
                            </Form.Group>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6Lc6YL0ZAAAAACkvqoeM-Wihm51UbZQps1rDMgNB"
                            />
                            <div className="text-center mt-3">
                                <button className="btn btn-blue" onClick={onSubmit}>ส่งข้อมูล</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}