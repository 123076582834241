import React from 'react'
import Slider from "react-slick";
import { slide_car_type_settings } from './slide-setting'

export default function CarType() {
    return (
        <div className="car-type pt-3">
            <div className="g-title-center">
                <div className="-bar">รถจักรยานยนต์ตามประเภท</div>
            </div>
            <Slider {...slide_car_type_settings} className="-slide">
                <div>
                    <div className="car-box" >
                        <div onClick={() => window.location.assign(`/products`)} className="-img"><img src={require('../images/car1.png')} alt="car1" /></div>
                        <div className="-content">
                            <div className="-title">นีโอสปอร์ตคาเฟ่</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="car-box">
                        <div onClick={() => window.location.assign(`/products`)} className="-img"><img src={require('../images/car2.png')} alt="car2" /></div>
                        <div className="-content">
                            <div className="-title">รถครอบครัว</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="car-box">
                        <div onClick={() => window.location.assign(`/products`)} className="-img"><img src={require('../images/car4.png')} alt="car4" /></div>
                        <div className="-content">
                            <div className="-title">เกียร์อัตโนมัติ</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="car-box">
                        <div onClick={() => window.location.assign(`/products`)} className="-img"><img src={require('../images/car5.png')} alt="car5" /></div>
                        <div className="-content">
                            <div className="-title">สปอร์ต</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="car-box">
                        <div onClick={() => window.location.assign(`/products`)} className="-img"><img src={require('../images/car3.png')} alt="car3" /></div>
                        <div className="-content">
                            <div className="-title">บ็อบเบอร์</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="car-box">
                        <div onClick={() => window.location.assign(`/products`)} className="-img"><img src={require('../images/car6.png')} alt="car6" /></div>
                        <div className="-content">
                            <div className="-title">ผจญภัย</div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>

    )
}