import React, { Component } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import './navbar.scss';
import { NavLink } from 'utils';
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.handleClick = this.handleClick.bind(this);
  }

  renderBurgerMenu() {
    return (
      <div className='menu'>
        <li className='link'>
          <NavLink to='/' classActive='link-active'>
            หน้าหลัก
          </NavLink>
        </li>
        <li className='link'>
          <NavLink to='/products' classActive='link-active'>
            ผลิตภัณฑ์
          </NavLink>
        </li>
        <li className='link'>
          <NavLink to='/compare' classActive='link-active'>
            เปรียบเทียบรุ่นรถ
          </NavLink>
        </li>
        <li className='link'>
          <NavLink to='/promo' classActive='link-active'>
            โปรโมชั่น
          </NavLink>
        </li>
        <li className='link'>
          <NavLink to='/news' classActive='link-active'>
            ข่าวประชาสัมพันธ์
          </NavLink>
        </li>
        <li className='link'>
          <NavLink to='/article' classActive='link-active'>
            บทความ
          </NavLink>
        </li>

        <li className='link'>
          <NavLink to='/contact' classActive='link-active'>
            ติดต่อเรา
          </NavLink>
        </li>
      </div>
    );
  }

  handleClick = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    let { visible } = this.state;
    switch (this.props.role) {
      case 'user':
        return (
          <div>
            <div className='navbar-top'>
              <div className='-menu-sm'>
                <HamburgerMenu
                  isOpen={visible}
                  menuClicked={this.handleClick.bind(this)}
                  width={18}
                  height={15}
                  strokeWidth={2}
                  rotate={0}
                  color='black'
                  borderRadius={0}
                  animationDuration={0.2}
                  className='ml-3'
                />
                <div className={visible ? 'drop-menu' : 'drop-menu-active'}>
                  {visible ? this.renderBurgerMenu() : null}
                </div>
              </div>

              <div className='-lefttop'>
                <div className='logotop'>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.location.assign('/')}
                    src={require('../../images/logo-color.png')}
                    alt='logo'
                  />
                </div>
              </div>

              <div className='-righttop'>
                <div className='icon'>
                  <img src={require('../../icon/icon-mail.png')} alt='logo' />
                </div>
                <div>chaisawat.kanoun@gmail.com</div>
                <div className='icon'>
                  <img src={require('../../icon/icon-phone.png')} alt='logo' />
                </div>
                <div>043-251-912</div>
                <div
                  className='icon-facebook'
                  onClick={() =>
                    window.location.assign('https://www.facebook.com/CSMcompany/')
                  }
                >
                  <img
                    src={require('../../icon/icon-facebook.png')}
                    alt='logo'
                  />
                </div>
                <div
                  className='icon-facebook'
                  onClick={() =>
                    window.location.assign('https://line.me/R/ti/p/@441gqype')
                  }
                >
                  <img src={require('../../icon/icon- line.png')} alt='logo' />
                </div>
              </div>
            </div>

            <div className='navbar'>
              <div className='-left'>
                <ul className='menu'>
                  <li className='link'>
                    <NavLink to='/' classActive='link-active'>
                      หน้าหลัก
                    </NavLink>
                  </li>
                  <li className='link'>
                    <NavLink to='/products' classActive='link-active'>
                      ผลิตภัณฑ์
                    </NavLink>
                    {/* <a href="#">ผลิตภัณฑ์</a> */}
                  </li>
                  <li className='link'>
                    <NavLink to='/compare' classActive='link-active'>
                      เปรียบเทียบรุ่นรถ
                    </NavLink>
                    {/* <a href="#">เปรียบเทียบรุ่นแรก</a> */}
                  </li>
                  <li className='link'>
                    <NavLink to='/promo' classActive='link-active'>
                      โปรโมชั่น
                    </NavLink>
                    {/* <a href="#">โปรโมชั่น</a> */}
                  </li>
                  <li className='link'>
                    <NavLink to='/news' classActive='link-active'>
                      ข่าวประชาสัมพันธ์
                    </NavLink>
                    {/* <a href="#">ข่าวประชาสัมพันธ์</a> */}
                  </li>
                  <li className='link'>
                    <NavLink to='/article' classActive='link-active'>
                      บทความ
                    </NavLink>
                  </li>

                  <li className='link'>
                    <NavLink to='/contact' classActive='link-active'>
                      ติดต่อเรา
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
        break;
      default:
        return <React.Fragment></React.Fragment>;
    }
  }
}
