import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './carousel.scss';

export default (data) => (
  <Carousel>
    {data.gallory.map((e, i) => (
      <div key={i}>
        <img alt='' src={e.path} />
      </div>
    ))}
  </Carousel>
);
