import React, { Component } from 'react'
import { Form } from 'react-bootstrap'

export default class Template extends Component {
    render() {
        return (
            <div className="p-5">
                <div className="g-title">g-title</div>
                <hr />
                <div className="g-title-left">g-title-left</div>
                <hr />
                <div className="g-title-center">
                    <div className="-bar">
                        g-title-center
                    </div>
                </div>
                <hr />
                <button className="btn btn-blue">btn btn-blue</button>
                <span className="mx-5"></span>
                <button className="btn btn-white">btn btn-white</button>
                <hr />
                <div>
                    <strong>checkbox</strong>
                    <div className="form-group g-custom-checkbox">
                        <div className="form-check">
                            <input type="checkbox" id="c6" className="form-check-input" defaultChecked />
                            <label htmlFor="c6" className="form-check-label">g-custom-checkbox</label>
                        </div>
                    </div>
                    <Form.Group className="g-custom-checkbox">
                        <Form.Check type="checkbox" id="c5" label="Form.Check g-custom-checkbox" defaultChecked />
                    </Form.Group>
                    <strong>checkbox inline</strong>
                    <div className="form-group g-custom-checkbox">
                        <div className="form-check form-check-inline" >
                            <input type="checkbox" id="c3" className="form-check-input" defaultChecked/>
                            <label htmlFor="c3" className="form-check-label">g-custom-checkbox</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input type="checkbox" id="c4" className="form-check-input" />
                            <label htmlFor="c4" className="form-check-label">g-custom-checkbox</label>
                        </div>
                    </div>
                    <Form.Group className="g-custom-checkbox">
                        <Form.Check inline type="checkbox" id="c1" label="Form.Check g-custom-checkbox" defaultChecked/>
                        <Form.Check inline type="checkbox" id="c2" label="Form.Check g-custom-checkbox" />
                    </Form.Group>
                </div>
                <hr />
                <div>
                    <strong>radio</strong>
                    <div className="form-group g-custom-radio">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="n1" id="i1" value="option1" defaultChecked />
                            <label className="form-check-label" htmlFor="i1">g-custom-radio</label>
                        </div>
                    </div>
                    <div className="form-group g-custom-radio">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="n1" id="i2" value="option2" />
                            <label className="form-check-label" htmlFor="i2">g-custom-radio</label>
                        </div>
                    </div>
                    <Form.Group className="g-custom-radio">
                        <Form.Check label="Form.Check g-custom-radio" type="radio" id="i3" name="n2" value="option1" defaultChecked/>
                    </Form.Group>
                    <Form.Group className="g-custom-radio">
                        <Form.Check label="Form.Check g-custom-radio" type="radio" id="i4" name="n2" value="option2" />
                    </Form.Group>
                    <strong>radio inline</strong>
                    <div className="form-group g-custom-radio">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="n3" id="i5" value="option1" defaultChecked />
                            <label className="form-check-label" htmlFor="i5">g-custom-radio</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="n3" id="i6" value="option2" />
                            <label className="form-check-label" htmlFor="i6">g-custom-radio</label>
                        </div>
                    </div>
                    <Form.Group className="g-custom-radio">
                        <Form.Check inline label="Form.Check g-custom-radio" type="radio" id="i7" name="n4" value="option1" defaultChecked />
                        <Form.Check inline label="Form.Check g-custom-radio" type="radio" id="i8" name="n4" value="option2" />
                    </Form.Group>
                </div>
            </div>
        )
    }
}
