import React, { Component } from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import './footer.scss';

export default class Footer extends Component {
  render() {
    switch (this.props.role) {
      case 'user':
        return (
          <>
            <div className='footer'>
              <div className='contact-container'>
                <div className='-content'>
                  <Row lg={7} xs={12} className='detail'>
                    <Col lg={3} className='logo'>
                      <Image src={require('../../images/logo-white.png')} />
                    </Col>
                    <Col className='text'>
                      <h4>บริษัท ชัยสัวสดิ์มอเตอร์ไบค์ จำกัด</h4>
                      <p>
                        ชัยสวัสดิ์มอเตอร์ไบค์ คือร้านขายรถมอเตอร์ไซค์ฮอนด้า
                        จังหวัด ขอนแก่น ที่ตอบโจทย์ผู้คนทุกระดับ
                        อย่างคุณได้เป็นอย่างดี ไม่ว่าคุณจะเป็นใคร
                        เรามีบริการที่เป็นกันเอง และรวดเร็ว ให้เกียรติคุณเสมอ
                      </p>
                    </Col>
                  </Row>
                  <Row lg={5} xs={12} className='contact'>
                    <Col lg={6} className='-header'>
                      <div>
                        <Image src={require('../../images/logo-store.png')} />
                        <div className='red-band'>
                          <h4>ติดต่อเรา</h4>
                        </div>
                      </div>
                    </Col>

                    <Col lg={12} className='contact-detail'>
                      <div className='logo'>
                        <i class='fas fa-map-marker-alt'></i>
                      </div>
                      <div className='text'>
                        <p>
                          528,528/1-3 หมู่ 10 ตำบลหนองโก
                          อำเภอกระนวน จังหวัดขอนแก่น 40170
                        </p>
                      </div>
                    </Col>

                    <Col lg={12} className='contact-detail'>
                      <div className='logo'>
                        <Image src={require('../../images/phone.png')} />
                      </div>
                      <div className='text'>
                        <p>043-251-912</p>
                      </div>
                    </Col>

                    <Col lg={12} className='contact-detail-border'>
                      <div className='logo'>
                        <Image src={require('../../images/fb.png')} />
                      </div>
                      <div className='text'>
                        <p>
                          ชัยสวัสดิ์มอเตอร์ไบค์ ร้านรถจักรยานยนต์ฮอนด้า ขอนแก่น
                        </p>
                      </div>
                    </Col>

                    <Col lg={12} className='contact-detail'>
                      <div className='logo'>
                        <Image src={require('../../images/line.png')} />
                      </div>
                      <div className='text'>
                        <p>@chaisawatmotorbike</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className='credit'>
              <p className='text'>WEBSITE BY PROJECTSOFT.CO.TH</p>
            </div>
          </>
        );
        break;
      default:
        return <React.Fragment></React.Fragment>;
    }
  }
}
